import React from 'react'
import { Component } from '../../framework/components'
import { SideBar } from '../../framework/containers'
import Menu from "../../framework/infra/Menu"
// import EmployerReport from '../pension/yearend/YEReport'
import YearEndReport from '../pension/yearend/YearEndReport'
import RetroContribAdjustmentPdf from '../financial/reporting/RetroContribAdjustmentPdf'
import EmployerCQPPStatusReport from "../member/reports/EmployerCQPPStatusReport"
import EmployerMessageWarningReport from '../member/reports/EmployerMessageWarningReport'
import EmployerRBCPaymentsContribsReport from '../financial/payments/EmployerRBCPaymentsContribsReport'
import YTDReport from '../financial/remittance/reports/YTDReport'

export default class EmployerReportPage extends Component {
    load() {
        return { menu: this.getMenu() }
    }
    view() {
        const { menu, selectedMenu } = this.state
        return <SideBar>
            <SideBar.Left className='w-15'>
                <Menu menu={Object.values(menu)} active={selectedMenu} variant="sidebar" onSelect={this.handleSelect.bind(this)}/> {/** we put active = selectedMenu when we're using this.busy() to keep the selectedMEnu*/}
            </SideBar.Left>
            <SideBar.Body> 
                {selectedMenu && selectedMenu.page && <selectedMenu.page query={selectedMenu.query} params={selectedMenu.params} reportTitle={selectedMenu.reportTitle}/>}
            </SideBar.Body>
        </SideBar>
    }
    handleSelect(selection) {
        this.busy(() => {
            this.setState({selectedMenu: selection})
            return Promise.resolve(selection)
        })
    }
    getMenu() {
        const { employer } = this.props 

        const menu = {
            members: {key: 'members', text: 'Members', open: false, subMenu: [
                {key: 'warnings', text: 'Tasks', page: EmployerMessageWarningReport, params: {employer: employer}},
                {key: 'cqppStatus', text: 'Member CQPP/Native/Tax Status', page: EmployerCQPPStatusReport, params: {employer: employer}},
            ]},
            auditor: {key: 'auditor', text: 'Auditor', open: false, subMenu: [
                {key: 'payment', text: 'RBC Payment and Contributions', page: EmployerRBCPaymentsContribsReport, params: {employer: employer}}
            ]},    
            summaries : {key: 'summaries', text: 'Summaries', open: false, subMenu: [
                {key: 'ytd', text: 'YTD report', page: YTDReport, params:{employer: employer, hideTable: true}}
            ]},
            mercer: {key: 'yearEnd', text: 'Year End', open: false, page: YearEndReport, params: {employer: employer}, icon: 'file'},
        }
        if (!employer.plan.isQuebec()) menu['summaries'].subMenu.push({key: 'contribPdf', text: 'Rate and Solvency Change Report', pdf: RetroContribAdjustmentPdf, fileName: employer.code + '-Rate-Solvency-Change-Report', params: {employer: this.props.employer} })
        return menu
    }
}