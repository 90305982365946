import { useState } from "react";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { Remittance, RemittanceDetail } from "../../../entities";
import { Table } from "../../../framework/components";
import { Title } from "../../../framework/containers";
import { Definition } from "../../../framework/infra";
import { Excel } from "../../../framework/utils";
import { array2Object, isString, moment } from "../../../framework/utils/helper";
import { RemittanceDetailService } from "../../../services";
import YearEndEmploymentSummary from "../../../entities/yearEnd/YearEndEmploymentSummary";

const YEUploadComments = ({file, report, onCancel, onSave}) => {
    const [details, setDetails] = useState();
    const headers = new Table.Headers(YearEndEmploymentSummary, 'sin, employerCmt, reviewedCmt, financialCmt, reasonDaysDiff, confStatus, finalPayOut, yeValidated, personalDataERInitialsCmt, statusConfirmationCmt, statusERInitialsCmt, memtdConfirmationCmt, memtdERInitialsCmt, maritalStatusERInitialsCmt, peERInitialsCmt, contERInitialsCmt, phERInitialsCmt, oneTimeConfirmationCmt, variationERInitialsCmt')

    useEffect(() => {
        let isMounted = true;

        if (isMounted) setDetails(initializeDetails());

        return () => { isMounted = false };
    }, []);

    const initializeDetails = () => {
        const details = [...report.details];
		const existingDetailsBySin = array2Object(details, 'sin');

        const StatusVALIDATE_sheet = file.findSheet('Status-VALIDATE');
        const StatusVALIDATE_sheet_data =  Excel.getSheetData(StatusVALIDATE_sheet, 3);
        const AnnualizedEarningsVALIDATE_sheet = file.findSheet('Annualized Earnings-VALIDATE');
        const AnnualizedEarningsVALIDATE_sheet_data =  Excel.getSheetData(AnnualizedEarningsVALIDATE_sheet, 3);
        const TerminationsCONFIRM_sheet = file.findSheet('Terminations-CONFIRM');
        const TerminationsCONFIRM_sheet_data =  Excel.getSheetData(TerminationsCONFIRM_sheet, 5);

        StatusVALIDATE_sheet_data.forEach(data => {
            procComment(data[1], { cmt: data[15], employerCmt: data[15], reviewedCmt: data[16] }, existingDetailsBySin);
        });

        AnnualizedEarningsVALIDATE_sheet_data.forEach(data => {
            procComment(data[1], { financialCmt: data[5] }, existingDetailsBySin);
        });

        /**
         * 
         * @param {*} cellValue 
         * @returns The value as a ISO date string if it was a date, otherwise returns the value as is.
         */
        const getDateOrComment = (cellValue) => (cellValue && moment.isDate(cellValue) && typeof cellValue.toISOString === 'function') ? cellValue.toISOString() : cellValue;

        TerminationsCONFIRM_sheet_data.forEach(data => {
            // Needed because all comments are defined as STRING in YearEndEmploymentSummary.definitions
            // but some of them might actually be dates, so we try to format them as date if possible
            // if their definition is changed to DATE, this code can be removed
            // See src/entities/yearEnd/YearEndEmploymentSummary.js

            const commentsFound = {
                // columns are 1-indexed, for example column A is 1, column B is 2, etc.

                // Personal Data ER Initials (Terminations-CONFIRM sheet, column P)
                personalDataERInitialsCmt: getDateOrComment(data[16]),
                // Status Confirmation (Terminations-CONFIRM sheet, column T)
                statusConfirmationCmt: data[20],
                // Status ER Initials (Terminations-CONFIRM sheet, column U)
                statusERInitialsCmt: getDateOrComment(data[21]),
                // Membership termination date Confirmation (Terminations-CONFIRM sheet, Column AA)
                memtdConfirmationCmt: data[27],
                // Membership termination date ER Initials (Terminations-CONFIRM sheet, Column AC)
                memtdERInitialsCmt: data[29],
                // Marital Status ER Initials (Terminations-CONFIRM sheet, column AH)
                maritalStatusERInitialsCmt: getDateOrComment(data[34]),
                // Total Pensionable Earnings ER Initials (Terminations-CONFIRM sheet, column AN)
                peERInitialsCmt: getDateOrComment(data[40]),
                // Total Contributions ER Initials (Terminations-CONFIRM sheet, Column AV)
                contERInitialsCmt: data[48],
                // Total Credited Service (PH?) ER Initials (Terminations-CONFIRM sheet, Columns BB)
                phERInitialsCmt: data[54],
                // Pensionable Earnings Validations: One time Confirmation (Terminations-CONFIRM sheet, Column BH)
                oneTimeConfirmationCmt: data[60],
                // Pensionable Earnings Validations: Variation ER Initials (Terminations-CONFIRM sheet, Column BI)
                variationERInitialsCmt: data[61],
            };
            
            procComment(data[1], commentsFound, existingDetailsBySin);
        });
            
        return Object.values(existingDetailsBySin);
    }

    const procComment = (dataSin, comments, existingDetailsBySin) => {
        const sin = Definition.types.SIN.getData(dataSin);
        if (existingDetailsBySin[sin]) {
            Object.getOwnPropertyNames(comments).forEach(prop => {
                if (comments[prop] === '' || (isString(comments[prop]) && comments[prop]?.toUpperCase() === 'BLANK') || !comments[prop]) comments[prop] = '';
                existingDetailsBySin[sin][prop] = comments[prop];
            })
            existingDetailsBySin[sin].yeValidated = String(existingDetailsBySin[sin].reviewedCmt).toLowerCase().includes('validated');
        }
    }

    const handleSave = async () => {
        const changes = {};
        for (const det of details) {
            changes[det.detailKey] = {
                cmt: det.cmt,
                employerCmt: det.employerCmt,
                financialCmt: det.financialCmt,
                reviewedCmt: det.reviewedCmt,
                reasonDaysDiff: det.reasonDaysDiff,
                confStatus: det.confStatus,
                finalPayOut: det.finalPayOut,
                personalDataERInitialsCmt: det.personalDataERInitialsCmt,
                statusConfirmationCmt: det.statusConfirmationCmt,
                statusERInitialsCmt: det.statusERInitialsCmt,
                memtdConfirmationCmt: det.memtdConfirmationCmt,
                memtdERInitialsCmt: det.memtdERInitialsCmt,
                maritalStatusERInitialsCmt: det.maritalStatusERInitialsCmt,
                peERInitialsCmt: det.peERInitialsCmt,
                contERInitialsCmt: det.contERInitialsCmt,
                phERInitialsCmt: det.phERInitialsCmt,
                oneTimeConfirmationCmt: det.oneTimeConfirmationCmt,
                variationERInitialsCmt: det.variationERInitialsCmt,
            };
        }
        await RemittanceDetailService.updateYEComments(changes);
        if(onSave) onSave(details);
    }

    return (<>
        {!details ? <p>Loading...</p>: 
        <><Title title='Year End Validation' onHide={onCancel}/>
        <Table id='messages-table' data={details} columns={headers}/>
        <Button key='save' onClick={handleSave}>Save</Button>
        <Button key='cancel' onClick={onCancel}>Cancel</Button></>}
    </>);

}

export default YEUploadComments;