import { RefMessage } from "../../framework/infra";
import { Definition, RefMessages } from "../../framework/infra/model";
import { RemittanceMessageConfig } from "./RemittanceMessageConfig";

export class RemittanceMessage extends RefMessage { 
    get config() { return {...RemittanceMessageConfig['default'] ,...RemittanceMessageConfig[this.code]}}
    get fixer() { return this.config.fixer};

    static messages = [
        ['nonEnroll', 'ASH ONLY: Update status to Eligible OR Not Eligible.', '', 'e'],
        ['noHours',  " ALERT: Reg/Overtime earnings reported without hours. ACTION: (a) Provide missing hours; or (b) this was an adjustment; no action", '', 'w'],
        ['hoursNoEarnings',  "ALERT: Reg/Overtime hours reported without earnings. ACTION: (a) Provide missing earnings; or (b) this was an adjustment; no action.", '', 'w'],
        ['noEarnings',  'No information received and the member is ACTIVE.\nEnter Earnings and Hours.\nIf member is on leave, change the status.', '', 'w'],
        ['negativeEarnings', 'ALERT: Negative earnings reported. ACTION: (a) if incorrect, submit new report; or (b) this was an adjustment; no action', '', 'i'],
        ['earningsOnMaternity', 'ALERT: Reg/Overtime earnings reported during 18-week MAT leave. ACTION: (a) If MAT top-up paid, report as “Non-Pensionable”; or (b) If she returned to work, report exact date; or (c) If earnings are for active period before MAT, no action.', '', 'w'],
        ['earningsOnDisability', 'ALERT: Earnings reported during LTD leave. ACTION: (a) If one-time earnings, explain; (b) If they returned to work, report exact date; or (c) If earnings are for active period before LTD, no action.' , '', 'w'],
        ['earningsOnLeave', 'Explain Earnings in "Comment" Column.\nMember is on leave. If member returned to work, amend status.', '', 'w'],
        ['earningsOnTerminated', 'ALERT: Earnings reported and membership terminated. ACTION: (a) Confirm if this is a final pay out.', '', 'i'],
        ['progressiveNoEarnings', 'ALERT: On progressive return & no earnings reported. ACTION: (a) Report missing earnings/hours;  or (b) Provide (1) leave type + (2) last day of work. ', '', 'w'],
        ['reducedDeemed', 'ALERT: Progressive return from LTD or Self Contributing Leave : LTD/Self Contributions pro-rated by the regular contributions. NO ACTION', '', 'i'],
        ['expectedDeemed', 'Actual deemed amounts do not match expected deemed amounts. Verify deemed calculations.', '', 'w'],
        ['maxContributionsReached', 'ALERT: Maximum contributions attained. ACTION: Reimburse excess to Member if any.', '', 'i'],
        ['maxVoluntaryReached', 'ALERT: Maximum voluntary contributions attained. ACTION: Reimburse excess to Member if any.', '', 'i'],
        ['ympeReached', 'ALERT: YMPE attained. ACTION: Ensure Employee Deduction Rate has been adjusted.', '', 'i'],
        ['midMonthEnr', 'ALERT: Mid-Month Enrolment. All earnings considered pensionable. ACTION: (a) No action as no impact on member benefit; or (b) Provide breakdown between pensionable & non pensionable earnings/hours.', '', 'w',''],
        ['midMonthRet', 'Age-60 retirement, Please enter pensionable earnings up to {AGE60}', '', 'w',''],
        ['noEarningsAt60', 'No earnings reported: Confirm if the employee has been laid off or was fired/quit.', '', 'w',''],
        ['closeEarningAdj', 'Adjustment required for terminated member. Earnings and/or hours reported are different than original. See super admin.', '', 'e', ''],
        ['eligEarning', 'ALERT: Earnings reported for Eligible employee-Employee Enrolled. ACTION: Provide the first day of work.', '', 'w', ''],
        ['nonPenEarnings', 'ALERT: Non pensionable earnings reported. No contributions calculated. ACTION: (a) if incorrect, submit new report', '', 'i', ''],
        ['autoPatLeave', 'ALERT: 18-week MAT leave completed. Placed on Parental Leave on {mat + 18 weeks + 1 day}. NO ACTION', '', 'i'],
        ['oteEarningsOnLeave', 'ALERT: One time earnings reported while member is absent. Member not reactivated. ACTION: (a) if incorrect, submit new report with regular earnings/hours; or (b) this was an adjustment; no action', '', 'i', '']


    ]

    static definitions = {
        config: {abstract: true},
        fixer: { abstract: true, type: Definition.types.UNDEFINED, text: 'Fixer' },
    }
}

export class RemittanceMessages extends RefMessages {
    static ref = RemittanceMessage
    
}