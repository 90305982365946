import moment from "moment";
import { RefMessage } from "../framework/infra/model"
import { Business } from "../framework/infra"
import { Contributions, Earning, Earnings, Participation, Remittance, RemittanceDetail, RemittanceDetails } from "../entities"
import { EmploymentTask } from "../entities/employment"
import { EmploymentTaskConfig } from "../entities/employment/EmploymentTaskConfig"
import { AdjustmentService, EmploymentService, RemittanceDetailService } from "../services"
import { appCmt, round } from "../framework/utils/helper"
import { contributionErrorInterval, earningErrorInterval, hourErrorInterval } from "../entities/yearEnd/YearEndConfig"
import { EVENT_SOURCE as source } from "../framework/infra/model/RefEvent";

const system = source.SYSTEM.key;
class Messages extends RefMessage {
    static messages = [
        ['reqFields', 'First Name, Last Name and SIN are required', 'e'],
        ['reqHiredDt', 'Hired Date is required ', 'e'],
        ['reqEmployer', 'Please select an Employer to Continue', 'e'],
        ['empExist', 'Employment already exist', 'e'],
        ['personNotExist', 'Please search and save person first', 'e'],
    ]
}

const REVIEW = 'Review with super admin';

export default class EmploymentBusiness extends Business {

    /**
     * 
     * @param {Employment} emp 
     * @param {Membership} membership 
     * @param {{code: string | undefined; cmt: string | undefined; employment: Employment | undefined; openRemittance: Remittance | undefined; eventsSource: string | undefined;} | undefined} newEventParams 
     */
    static createNewPPForEmp(emp, membership, newEventParams){
        //Create a new participation
        const lastNo = Math.max(0, ...membership.participations.all.filter(pp => !isNaN(Number(pp.no))).map(pp => Number(pp.no)));
        const newPP = new Participation()
        newPP.no = String(lastNo + 1);
        newPP.employment = emp;
        newPP.membership = membership;
        emp.participation = newPP;
        const empHiredEvent = emp.getHiredEvent();
        if(newEventParams?.code !== 'newPot') {
            newPP.addEvent({code: 'newPot', ets: empHiredEvent?.ets ? (empHiredEvent.ets + 10) : moment().valueOf(), source: newEventParams?.eventsSource || system },{openRemittance: newEventParams?.openRemittance});
        }
        if(newEventParams) {
            const event = {code: newEventParams.code, ets: empHiredEvent?.ets ? (empHiredEvent.ets + 100) : moment().valueOf(), cmt: newEventParams.cmt, source: newEventParams?.eventsSource || system };
            const eventParams = {};
            if(event.code ==="inegNotF"){
                eventParams.openEmployment = newEventParams?.employment;
            }
            if(newEventParams?.openRemittance){
                eventParams.openRemittance = newEventParams.openRemittance;
            }
            newPP.addEvent(event, eventParams);
        }

        newPP.membership.participations.push(newPP)
        newPP.employments.push(emp)
    }

    /**
     * 
     * @param {Employment} emp 
     * @param {Participation} pp 
     * @param {string | undefined} ppEventCode 
     * @param {string | undefined} cmt 
     * @param {{openRemittance: Remittance | undefined; eventsSource: string | undefined;} | undefined} options 
     */
	static mergeEmployment(emp, pp, ppEventCode, cmt="", options) {
        emp.participation = pp
		pp.employments.push(emp)
        if(ppEventCode) pp.addEvent({code: ppEventCode, cmt: cmt, source: options?.eventsSource || system}, {openRemittance: options?.openRemittance})
	}

    /** Calculates the expected remittance details deemed amounts for the given periods betwen startPeriod and 
     * endPeriod for the input employment and compares with actual app deemed amounts
     * 
     * @param {{employment: Employment; startPeriod: any; endPeriod: any; dbDetails: any;}} object 
     * @returns an object containing deemed amounts (expected, actual and difference) and the expected calculated details
     */
    static async checkDeemedAmounts({employment, startPeriod, endPeriod, dbDetails}) {
        let diffEarn = new Earnings();
        let diffContribs = new Contributions();
        let details = dbDetails;
        
        // last detail should be the year end period to include any earnings/contributions/adjustments from the year end period
        let lastDetail = (details.find(det => det.period.isSameYear(endPeriod) && det.period.yearEnd));
        if (!lastDetail && details.last?.period.year === endPeriod.year) lastDetail = details.last;
        else if (!lastDetail) lastDetail = new RemittanceDetail({
            remittance: new Remittance({ employer: employment.employer, period: endPeriod }),
            employment, 
            participation: employment.participation
        });

        // filter out retro adjustments (from other years)
        const currentYearAdjustments = lastDetail.adjustments.getFiltered(adj => adj.targetPeriod.isSameYear(endPeriod));

        // clone last detail to avoid altering the original
        let clonedLastDetail = lastDetail.clone();

        // filter out retro adjustments (from other years)
        clonedLastDetail.adjustments = currentYearAdjustments;
        clonedLastDetail = await AdjustmentService.applyAdjustmentsInEffectivePeriod(clonedLastDetail.clone(), startPeriod, endPeriod);

        // re-init adjustmentContributions and adjustmentEarnings without retro adjustments
        // options.excludeRetroactiveAdjustments excludes the retroactive adjustments from `allYearAdjustment` and `adjustmentContributions`
        // options.excludeRetroactiveAdjustments is false by default in clonedLastDetail.initDetailAdjustment(adjustments, options)
        clonedLastDetail.initDetailAdjustment(currentYearAdjustments);

        // GET DETAILS FOR PERIODS
        const clonedDetails = RemittanceDetails.getClonedForEmploymentByPeriods({
            employment,
            startPeriod,
            endPeriod,
            details,
        });

        // TRIGGER EXPECTED CALCS
        const { calculatedDetails, expected } = RemittanceDetailService.calculateExpectedDeemed(clonedDetails);
        const deemed = { ltd: "l1", mat: "m1", slf: "s1" };

        Object.entries(deemed).forEach(([key, code]) => {
            const expectedForKey = expected[key];
            const hoursKey = `${key}Hours`;
            const hasEarningsDiff = Math.abs(expectedForKey.earnings - clonedLastDetail.ytdEarnings[key]) > earningErrorInterval;
            const hasHoursDiff = Math.abs(expectedForKey.hours - clonedLastDetail.ytdEarnings[hoursKey]) > hourErrorInterval;
            const hasContribsDiff = Math.abs(expectedForKey.contributions - clonedLastDetail.ytdContributions[key]) > contributionErrorInterval;
            if(hasEarningsDiff || hasHoursDiff) {
                if (expectedForKey.earnings !== clonedLastDetail.ytdEarnings[key] || expectedForKey.hours - clonedLastDetail.ytdEarnings[hoursKey]) {
                    diffEarn.add([new Earning({code, amount: round(expectedForKey.earnings - clonedLastDetail.ytdEarnings[key]), hours: round(expectedForKey.hours - clonedLastDetail.ytdEarnings[hoursKey]), earningType: {code}})]);
                }
            }
            if(hasContribsDiff && expectedForKey.contributions !== clonedLastDetail.ytdContributions[key]) {
                diffContribs.addAmount(key.toUpperCase(), round(expectedForKey.contributions - clonedLastDetail.ytdContributions[key]));
            }

        });

        return {
            actual: {
                earnings: clonedLastDetail.ytdEarnings.deemed,
                contributions: clonedLastDetail.ytdContributions.deemed,
                hours: clonedLastDetail.ytdEarnings.deemedHours
            },
            difference: {
                earnings: diffEarn,
                contributions: diffContribs,
            },
            expected,
            calculatedDetails,
        }
    }

    /**
     * 
     * @param {string} code 
     * @param {Employment} employment 
     * @param {boolean | undefined} isLastEmployment 
     */
    static validateShowMultipleTasks(code, employment, isLastEmployment) {
        const eventList = EmploymentTaskConfig[code].validation(employment, isLastEmployment);
        if (eventList.length === 0) {
            employment.tasks.remove((message) => message.code === code);
        } else if (eventList.length < employment.tasks.filter(task => task.code === code).length) {
            employment.tasks.pullFilter((task) => eventList.find((event) => event.ets === task.params?.ets?.value));
        }
        eventList.forEach((event) => {
            const existingTask = employment.tasks.find((message) => {
                const params =
                    EmploymentTaskConfig[code].params &&
                    EmploymentTaskConfig[code].params(employment, event);
                const sameParams = params?.reduce((prevBool, currentParam) => {
                    const paramExists = message.params.all.find(
                        (param) => param.key === currentParam.key
                    );
                    return Boolean(
                        prevBool &&
                            paramExists &&
                            paramExists.value === currentParam.value
                    );
                }, true);
                return message.code === code && sameParams;
            });
            if (!existingTask) {
                employment.tasks.pushNew({
                    code: code,
                    params: EmploymentTaskConfig[code].params
                        ? EmploymentTaskConfig[code].params(employment, event)
                        : [],
                });
            }
        });
    }

   
    /**
     * validate the employment and add tasks if needed
     * @param {Employment} employment 
     * @param {boolean | undefined} isLastEmployment 
     * @returns The updated employment
     */
    static validateTask(employment, isLastEmployment){
        employment.tasks.reset();
        employment.events.sortEvents();
        const validationTask = EmploymentTask.validationTask()
        validationTask.forEach(validTask => {
            const code = validTask.key;
            const task = employment.tasks.find(message => message.code === code);
            if (EmploymentTaskConfig[code].showMultiple) {
                this.validateShowMultipleTasks(code, employment, isLastEmployment);
            } else {
                if (!task) {
                    if (
                        EmploymentTaskConfig[code].validation(employment, isLastEmployment) &&
                        !(
                            (EmploymentTaskConfig[code].removeValidation &&
                            EmploymentTaskConfig[code].removeValidation(
                                employment
                            )) || (EmploymentTaskConfig[code].nextTask && 
                            EmploymentTaskConfig[EmploymentTaskConfig[code].nextTask].validation)
                        )
                    ) {
                        return employment.tasks.pushNew({
                            code: code,
                            params: EmploymentTaskConfig[code].params
                                ? EmploymentTaskConfig[code].params(employment)
                                : [],
                        });
                    }
                } else {
                    if (EmploymentTaskConfig[code].removeValidation) {
                        if (
                            EmploymentTaskConfig[code].removeValidation(
                                employment
                            )
                        )
                            employment.tasks.remove(
                                (message) => message.code === code
                            );
                    } else if(EmploymentTaskConfig[code].nextTask){ //If the next task validation is true, remove the previous task. 
                        if(EmploymentTaskConfig[EmploymentTaskConfig[code].nextTask].validation){
                            employment.tasks.remove(
                                (message) => message.code === code
                            );
                        }
                    }else {
                        if (!EmploymentTaskConfig[code].validation(employment, isLastEmployment))
                            employment.tasks.remove(
                                (message) => message.code === code
                            );
                    }
                }
            }
        })

        employment.tasks = this.getTasksToShow(employment);

    }

    /**
     * Get all the employment tasks to show, depending on the employment's status.
     * 
     * If participation status should show task (if participation is not potential or cancelled or ineligible or terminated or receiving pension or pension suspended or eligible period), returns all tasks.
     * Otherwise return only the tasks with flag `alwaysShow`
     */
    static getTasksToShow(employment) {
        if(employment.participation?.lastStatusEvent?.status?.shouldShowTask?.()){
            return employment.tasks;
        } else {
            return employment.tasks.getFiltered((task) => task.config.alwaysShow);
        }

    }
   
    /**
     * validate the employment and add tasks if needed
     * @param {Employment} employment 
     * @param {boolean | undefined} isLastEmployment 
     * @returns The updated employment
     */
    static validate(employment, isLastEmployment) {
        this.validateTask(employment, isLastEmployment)
        employment.tasks.remove(task => task.__code === '');
        return employment
    }

    static flows = { 
        newEmployment: {
            searchPerson: { key: '1.1', 
                condition: { 
                    personFound: {},
                    newPerson: {},
                },
                next: 'validate'
            },
            validate: {
                fields: {}
            }
        }

    }



    static actions = {
        searchPerson: {}
    }
    
    static rules = {
        fields: {
            'person.sin': {mandatory: true, msg: Messages.messages.reqFields},
            'person.firstName': {mandatory: true, msg: Messages.messages.reqFields},
            'person.lastName': {mandatory: true, msg: Messages.messages.reqFields},
            'hiredDate': {mandatory: true, msg: Messages.messages.reqHiredDt},
            'employer.code': {mandatory: true, msg: Messages.messages.reqEmployer},
        }
    }
    
    static getMandatoryFields(path) {
        return Object.getOwnPropertyNames(this.rules.fields).reduce((mandatoryFields, fieldName) => {
            const msg = this.rules.fields[fieldName]
            if (msg.mandatory) {
                if (!path) mandatoryFields[fieldName] = msg
                else {
                    const pathProps = fieldName.split('.')
                    const propName = pathProps.splice(1)[0]
                    const fieldPath = pathProps.join('.')
                    if(path === fieldPath) mandatoryFields[propName] = msg
                }
            }
            return mandatoryFields
        }, {})
    }

    static messages = Messages

}
