
import { Definition, RefEnum } from '../../framework/infra'

export default class EmploymentStatus extends RefEnum {
    
    /** employment status is `act` (Employed) or `apr` (Progressive Return) */
    isActive() { return ['act', 'apr'].includes(this.key) }
    /** employment status is `apr` (Progressive Return) */
    isProgressiveReturn() { return this.key === 'apr'} 
    
    /** employment status is `tlo` (Lay-off (intention to rehire)) */
    isLayOff() { return this.key === 'tlo' }
    /** employment status is `trm` (Employment Terminated) or `tfq` (Fired/Quit) or `tcl` (Employment Closed) or `trf` (Transferred Out) or `trd` (Retired) or `tde` (Deceased) or `tex` (Leave Expired) */
    isTerminated() { return ['trm', 'tfq', 'tcl', 'trf', 'trd', 'tde', 'tex'].includes(this.key) }
    /** employment status is not `trm` (Employment Terminated) or `tfq` (Fired/Quit) or `tcl` (Employment Closed) or `trf` (Transferred Out) or `trd` (Retired) or `tde` (Deceased) or `tex` (Leave Expired) */
    isNotTerminated() { return !this.isTerminated() }
    /** employment status is `trm` (Employment Terminated) or `tfq` (Fired/Quit) or `tcl` (Employment Closed) or `trf` (Transferred Out) or `trd` (Retired) or `tde` (Deceased) or `tex` (Leave Expired) */
    isPermanentTerminated() { return ['trm', 'tfq', 'tcl', 'trf', 'trd', 'tde', 'tex'].includes(this.key) }

    /** employment status is `tcl` (Employment Closed) or `tfq` (Fired/Quit) or `trf` (Transferred Out) or `tex` (Leave Expired) */
    isFiredQuit() { return  ['tcl', 'tfq', 'trf', 'tex'].includes(this.key) }
    /** employment status is `trf` (Transferred Out) */
    isTransfer() { return this.key === 'trf'}
    /** employment status is `trd` (Retired) */
    isRetired() { return this.key === 'trd' }
    /** employment status is `tde` (Deceased) */
    isDeceased() { return this.key === 'tde' }
    /** employment status is `mat` (Maternity) */
    isMaternity() { return this.key === 'mat' }
    /** employment status is `ltd` (Long Term Disability) */
    isLtd() { return this.key === 'ltd' }
    /** employment status is `loa` (Leave of Absence) */
    isLoa() { return this.key === 'loa' }
    /** employment status is `lst` (Approved Medical Leave (ie: STD, CNESST)) */
    isLst() { return this.key === 'lst' }
    /** 
     * employment status is `loa` (Leave of Absence) or `lpp` (Parental/Paternity leave) or `lpl` (Parental leave) or `lpy` (Paternity leave)
     * or `lcc` (Compassionate care/Adoption leave) or `lco` (Compassionate care leave) or `lad` (Adoption Leave)
     * or `lst` (Approved Medical Leave (ie: STD, CNESST)) or `lso` (Other) or `leo` (Education/Sabbatical/Other) or `led` (Education leave (Full Time Student))
     * or `tlo` (Lay-off (intention to rehire)) or `lsw` (Suspended without pay) or `lms` (Military Service)
     * or `lun` (Leave Unspecified) or `lsp` (Suspended with pay)
     */
    isOnLeave() { 
        return [
            'loa', 'lpp', 'lpl', 'lpy', 'lcc', 'lco', 'lad', 
            'lst', 'lso', 'leo', 'led', 'tlo', 'lsw', 'lms', 
            'lun', 'lsp'
        ].includes(this.key);
    }
    /** 
     * employment status is `ltd` (Long Term Disability) or `lst` (Approved Medical Leave (ie: STD, CNESST)) or `mat` (Maternity)
     * and employment status is `loa` (Leave of Absence) or `lpp` (Parental/Paternity leave) or `lpl` (Parental leave) or `lpy` (Paternity leave)
     * or `lcc` (Compassionate care/Adoption leave) or `lco` (Compassionate care leave) or `lad` (Adoption Leave)
     * or `lst` (Approved Medical Leave (ie: STD, CNESST)) or `lso` (Other) or `leo` (Education/Sabbatical/Other) or `led` (Education leave (Full Time Student))
     * or `tlo` (Lay-off (intention to rehire)) or `lsw` (Suspended without pay) or `lms` (Military Service)
     * or `lun` (Leave Unspecified) or `lsp` (Suspended with pay)
     * 
    */
    isLeaveException() {
        return (this.isLtd() || this.isLst() || this.isMaternity()) && this.isOnLeave()
    }
    /** 
     * employment status is `lpp` (Parental/Paternity leave) or `lpl` (Parental leave) or `lpy` (Paternity leave)
     * or `lcc` (Compassionate care/Adoption leave) or `lco` (Compassionate care leave) or `lad` (Adoption Leave)
     * or `lst` (Approved Medical Leave (ie: STD, CNESST))
     * */
    eligibleForSelfContribution() { 
        return ['lpp', 'lpl', 'lpy', 'lcc', 'lco', 'lad', 'lst']
            .includes(this.key);
    }
    /** 
     * employment status is `lpp` (Parental/Paternity leave) or `lpl` (Parental leave) or `lpy` (Paternity leave)
     * or `lcc` (Compassionate care/Adoption leave) or `lco` (Compassionate care leave) or `lad` (Adoption Leave)
     * or `lst` (Approved Medical Leave (ie: STD, CNESST)) or `ltd` (Long Term Disability) or `mat` (Maternity)
     * */
    isDeemedStatus() { return this.eligibleForSelfContribution() || this.isLtd() || this.isMaternity() }

    isParentalLeave() { return this.key === 'lpl' }

    static types = {
        /** act: Employed */
        act: new EmploymentStatus('act', 'Employed'),
        /** apr: Progressive Return */
        apr: new EmploymentStatus('apr', 'Progressive Return'),
        /** mat: Maternity */
        mat: new EmploymentStatus('mat', 'Maternity'),
        /** loa: Leave of Absence */
        loa: new EmploymentStatus('loa', 'Leave of Absence'),
        /** ltd: Long Term Disability */
        ltd: new EmploymentStatus('ltd', 'Long Term Disability'),
        /** lpp: Parental/Paternity */
        lpp: new EmploymentStatus('lpp', 'Parental/Paternity'),
        /** lpl: Parental */
        lpl: new EmploymentStatus('lpl', 'Parental'),
        /** lpy: Paternity */
        lpy: new EmploymentStatus('lpy', 'Paternity'),
        /** lcc: Compassionate care/Adoption */
        lcc: new EmploymentStatus('lcc', 'Compassionate care/Adoption'),
        /** lco: Compassionate care */
        lco: new EmploymentStatus('lco', 'Compassionate care'),
        /** lad: Adoption */
        lad: new EmploymentStatus('lad', 'Adoption'),
        /** lst: Approved Medical Leave (ie: STD, CNESST) */
        lst: new EmploymentStatus('lst', 'Approved Medical Leave (ie: STD, CNESST)'),
        /** leo: Education/Sabbatical/Other */
        leo: new EmploymentStatus('leo', 'Education/Sabbatical/Other'),
        /** lso: Other */
        lso: new EmploymentStatus('lso', 'Other'),
        /** led: Education leave (Full Time Student) */
        led: new EmploymentStatus('led', 'Education leave (Full Time Student)'),
        /** lms: Military Service */
        lms: new EmploymentStatus('lms', 'Military Service'),
        /** lsw: Suspended without pay */
        lsw: new EmploymentStatus('lsw', 'Suspended without pay'),
        /** lsp: Suspended with pay */
        lsp: new EmploymentStatus('lsp', 'Suspended with pay'),
        /** lun: Leave Unspecified */
        lun: new EmploymentStatus('lun', 'Leave Unspecified'),
        /** trm: Employment Terminated */
        trm: new EmploymentStatus('trm', 'Employment Terminated'),
        /** tcl: Employment Closed */
        tcl: new EmploymentStatus('tcl', 'Employment Closed'),
        /** tlo: Lay-off (intention to rehire) */
        tlo: new EmploymentStatus('tlo', 'Lay-off (intention to rehire)'),
        /** tfq: Fired/Quit */
        tfq: new EmploymentStatus('tfq', 'Fired/Quit'),
        /** trd: Retired */
        trd: new EmploymentStatus('trd', 'Retired'),
        /** tde: Deceased */
        tde: new EmploymentStatus('tde', 'Deceased'),
        /** trf: Transferred Out */
        trf: new EmploymentStatus('trf', 'Transferred Out'),
        /** tex: Leave Expired */
        tex: new EmploymentStatus('tex', 'Leave Expired'),
    }
    static default = new EmploymentStatus('', 'Not Recognized')
}

