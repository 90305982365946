import moment from "moment";
import { LONG_FORMAT } from "../../framework/utils/helper";

const Email = (props) => <div>
    <div>
        <div>
            <b><i><u>
                [IF CRITICAL ISSUES WITH HIRE DATE/JOIN DATE/ELIGIBILITY DATE/DOB/CQPP STATUS: SEND TO ADMINISTRATOR]<br/>
                Dear {props.admin?.person.firstName ?? '[Administrator-FirstName]'},
                <br/><br/>
                Thank you, we have received the Enrolment form for {props.participation.person.firstName +' '+props.participation.person.lastName}, however there are a few issues you need to address before we can finalize:
            </u></i></b>
        </div>
        <ul>
            <li><b><i><u>{'{FILL OUT ISSUE HERE – always give action for admin to do not what is missing}'}</u></i></b></li> <br/>
        </ul>
        <div>
            <b><i><u>
                <br/>
                Please contact us if you have any questions.<br/><br/>
                Thank you,
                <br/><br/>
                [IF CRITICAL ISSUES MISSING SIGNATURE ON BENEFICIARY DESIGNATIN (MEMBER+SPOUSE): SEND TO MEMBER]<br/><br/>
                Dear {props.participation.person.firstName},
                <br/>
            </u></i></b>
        </div>
        <div>
            This is to confirm that we recently received your Enrolment Form regarding your membership in the James Bay Cree-Naskapi Pension Plan (“JBCNPP”). Because the JBCNPP is a defined benefit pension plan, your benefits are determined using your pensionable earnings and service, as well as some key information provided on your Enrolment form.
            <br/><br/>
            To ensure the information we have on file is accurate, please review the data below and inform us if any corrections are required. If we have not heard from you in the next 30 days: (1) I confirm the data listed below, (2) I consent that should the Plan ever need to refund me any amounts and I have not responded within the given timeframe, that my employer share my direct deposit instructions with the Plan Coordinator for ease of payment.
        </div>    
        <div>
            <br/>
            <b><i><u>We cannot accept your Beneficiary Designation</u> without your signature and a witness signature. The witness must be over the age of 18 and cannot be one of your designations. Please see your employer to sign and return the form.</i></b>
        </div>        
    </div>
    <div><br/>
    <b>Personal Information:</b>
        <ul>
            <li>Name: {props.participation.person.firstName} {props.participation.person.lastName}</li>
            <li>Date of Birth: {props.participation.person.dob? moment(props.participation.person.dob).utc().format(LONG_FORMAT) : ''} </li>
            <li>Sex: {props.participation.person.getOptionText('gender')}</li>
        </ul>
    </div>
    <div><br/>
    <b>Contact Information:</b>
        <ul>
            <li>Language: {props.participation.person.getOptionText('lng')}</li>
            <li>Address: {props.participation.person.address?.desc}</li>
            <li>Email: {props.participation.person.defaultEmail}</li>
            <li>Phone: {props.participation.person.defaultPhone}</li>
        </ul>
    </div>
    <div><br/>
        <b>Employment Information (as per your employer):</b>
            {props.participation.employments.map((x, i)=> {
                return <div><ul>
                    <li>Employer: {x.employer.formatedName}</li>
                    <li>Legislative Jurisdiction: {x.employer.getOptionText('rst')} </li>
                    <li>Hire Date: {x.hiredDate ? moment(x.hiredDate).format(LONG_FORMAT) : ''}</li>
                    <li>Employment Type: {x.getOptionText('employmentType')}</li>
                    <li>Native Status: {x.getOptionText('isN')}</li>
                    <li>Contributes to Canada/Quebec Pension Plan: {x.getOptionText('isCQ')}</li>
                    <li>Pays Tax: {x.getOptionText('isTP')}</li>
                </ul>
                {i !==0 ? <br/> : null}
                </div>
            })}
    </div>
    <div><br/>
        <b>Pension Membership Information (as per your employer):</b>
        <ul>
            { props.participation.firstEligibility !== '' && <li>JBCNPP Eligibility Date: {moment(props.participation.firstEligibility).format(LONG_FORMAT)} </li>}
            <li>JBCNPP Enrolment Date: {props.participation.joinDt ? moment(props.participation.joinDt).format(LONG_FORMAT) : ''}</li>
            { props.reason && <li> If Join Date is not January 1, reason: {props.reason}</li>}
        </ul>
    </div>
    <div><br/>
        <b>Spouse & Beneficiary Information:</b>
        <br/>In the event of your death before retirement, your spouse is entitled to benefits regardless of other beneficiaries you name.
        <ul>
            <li>Marital Status: </li>
            <li>Spouse's Name: </li>
            <li>Spouse's Date of Birth: </li>
            <li>Beneficiary Designation: <br/>
                { props.participation.beneficiaries.latestGroup.length > 0 ? 
                    props.participation.beneficiaries.latestGroup.map(x => <>- {x.details}<br/></>)
                : <>- None<br/></> }
                {props.participation.beneficiaries.latestGroup.length > 0? '- Guardian (if beneficiary is a minor)*:':'' } 
            </li>
        </ul>
    </div>
    <div>
        Please do not hesitate to contact us if you have questions.
        <br/><br/>
        Thank you,
    </div>
</div>

export default Email;