import React from "react"
import { renderToString } from 'react-dom/server'
import { Excel } from '../../../framework/utils'
import { DATE_FORMAT, moment, sort, LONG_FORMAT } from "../../../framework/utils/helper"
import { Component } from '../../../framework'
import { Icon, Table } from '../../../framework/controls'
import { renderAmountEmptyFor0, renderNumBadge, renderNumberEmptyFor0, renderMultipleWarnings } from '../../../framework/utils/renders'
import RefMessage from "../../../framework/infra/model/RefMessage"
import { RemittanceDetail, EarningType }  from "../../../entities"
import ReportsMenu from './ReportsMenu'
import { formatAmount } from "../../../framework/utils/formating"

export default class DeductionsEarningsTable extends Component {
    
    view(){
        const { remittanceDetails, earningTypes, hide, onSelectedDetail, onSelectedEmployee, view, onTableLoad, editable, onSelectedMessage, onSelectedTaskList, onDetailChange, onVoluntaryChanged } = this.props
        if (!hide) {
			var columnsMap = {}
			if (view === 'earnings') {
                columnsMap = new Table.Headers(RemittanceDetail, 'employee, total')
                columnsMap['employee'].columns = getEmployeeColumns(handleSelectedEmployee).namedHeadersList
                columnsMap['total'].columns = new Table.Headers(RemittanceDetail, 'earnings.deductable, earnings.deductableHours').namedHeadersList
                Object.values(getEarningColumns(earningTypes, editable, onDetailChange)).forEach(earningCol => columnsMap[earningCol.name] = earningCol)
				getMessageCommentColumns(editable, handleSelectedMessage, handleTasklist).namedHeadersList.forEach(messageCol => columnsMap[messageCol.name] = messageCol)
                columnsMap['employee'].title = "Employees"
                columnsMap['total'].title = "Total"
                columnsMap['total'].columns[0].minWidth = 140
                columnsMap['total'].columns[1].className = 'table-col-separator'
                columnsMap['total'].columns[1].minWidth = 80
			
			} else if (view === 'summary') {
                columnsMap = getEmployeeColumns(handleSelectedEmployee)
                columnsMap.addHeaders(['ytdEarnings.regular', 'ytdEarnings.overtime', 'ytdEarnings.oneTime', 'ytdEarnings.deductable', 'ytdEarnings.deemed', 'ytdContributions.reg', 'ytdContributions.mat', 'ytdContributions.ltd', 'ytdContributions.slf', 'ytdContributions.vol','workSchedule.weeklySchedule', 'ytdCreditedService'])
				delete columnsMap['employee.status.sts']
                Table.addTopSum(columnsMap, 'ytdEarnings.regular, ytdEarnings.overtime, ytdEarnings.oneTime, ytdEarnings.deductable, ytdEarnings.deemed, ytdContributions.reg, ytdContributions.mat, ytdContributions.ltd, ytdContributions.slf, ytdContributions.vol')
                'ytdEarnings.regular, ytdEarnings.overtime, ytdEarnings.oneTime, ytdEarnings.deductable, ytdEarnings.deemed, ytdContributions.reg, ytdContributions.mat, ytdContributions.ltd, ytdContributions.slf, ytdContributions.vol'.split(' ').join('').split(',').forEach(col => columnsMap[col].title = 'YTD ' + columnsMap[col].title)
			} else { //default is deductions
                columnsMap = getEmployeeColumns(handleSelectedEmployee)
                columnsMap.addHeaders(['contributions.reg','contributions.mat', 'contributions.ltd', 'contributions.slf', 'contributions.vol', 'contributions.total', 'adjustmentContributions.total'])
                getMessageCommentColumns(editable, handleSelectedMessage, handleTasklist).namedHeadersList.forEach(messageCol => columnsMap[messageCol.name] = messageCol)
                columnsMap['contributions.vol'].editor = editable ? 'number' : null
                columnsMap['contributions.vol'].cellEdited = onVoluntaryChanged;
                columnsMap['contributions.total'].format = renderContributionWithWarning
                columnsMap['adjustmentContributions.total'].hideIfEmpty = true
                columnsMap['adjustmentContributions.total'].title = 'Contribution Adjustments'
        		Table.addTopSum(columnsMap, 'contributions.reg, contributions.mat, contributions.ltd, contributions.slf, contributions.vol, contributions.total, adjustmentContributions.total')
            }


            return <Table id='earnings-table' 
                data={[...remittanceDetails.all]} 
                columns={columnsMap} 
                dataKey='employment.person.sin'
                onTableLoad={onTableLoad}
                onSelect={onSelectedDetail} 
                searchable selectable 
                editable
                sort='employment.person.name'
                className="maxvh-55"
                rowFormatter={formatRows}
            />
        }

        function handleSelectedEmployee(e, cell) {
            if(onSelectedEmployee) onSelectedEmployee(cell._cell.row.data)
        }
        function handleSelectedMessage(e, cell) {
            if(onSelectedMessage) onSelectedMessage(cell._cell.row.data)
        }
        function handleTasklist(e, cell) {
            if(onSelectedTaskList) onSelectedTaskList(cell._cell.row.data)
        }
    }

    download() {
        const { view, earningTypes } = this.props
        var excelHeaders
        const eeHeaders = 'employment.person.sin, employment.person.name, employmentPpStatusDesc, employmentStatusEffDate, '
        if (view === 'earnings') {
            excelHeaders = new Excel.Headers(RemittanceDetail, eeHeaders + 'earnings.deductable')
            Object.assign(excelHeaders, ReportsMenu.getEarningTypesExcelHeaders(earningTypes), new Table.Headers(RemittanceDetail, 'messagesDesc, formalEmployersTasksDesc, employerCmt'))
        } else if (view === 'summary') {
            excelHeaders = new Excel.Headers(RemittanceDetail, eeHeaders + 'ytdEarnings.regular, ytdEarnings.overtime, ytdEarnings.oneTime, ytdEarnings.deductable, ytdEarnings.deemed, ytdContributions.reg, ytdContributions.mat, ytdContributions.ltd, ytdContributions.slf, ytdContributions.vol, ytdEarnings.hours, ytdEarnings.regularHours, ytdEarnings.overtimeHours, workSchedule.wklySch, ytdCreditedService')
            excelHeaders['workSchedule.wklySch'].format = (val) => val ? Number(val) : ''
        } else {
            excelHeaders = new Excel.Headers(RemittanceDetail, eeHeaders + 'contributions.reg, contributions.mat, contributions.ltd, contributions.slf, contributions.vol, contributions.total, adjustmentContributions.total, messagesDesc ,formalEmployersTasksDesc, employerCmt')
            excelHeaders['messagesDesc'].title = 'EMPLOYER ACTIONS REQUIRED';
            excelHeaders['formalEmployersTasksDesc'].title = 'ADMIN ONLY - Outstanding Tasks {TEAM TO HIDE before sending}'
        }
        excelHeaders['employmentStatusEffDate'].title = 'Effective Date'
        const excel = new Excel(this.props.view)
        excel.addSheet(excelHeaders, sort(this.props.remittanceDetails.all, 'employment.person.name'))

        excel.download()
    }
}


function getEmployeeColumns(handleSelectedEmployee) {
    const columnsMap = new Table.Headers(RemittanceDetail, 'employment.person.sin, employment.person.name, employmentPpStatusDesc, employmentStatusEffDate')
    columnsMap['employment.person.sin'].minWidth = 130
    columnsMap['employment.person.sin'].maxWidth = 160
    columnsMap['employment.person.sin'].headerFilter = true
    columnsMap['employment.person.name'].minWidth = 200
    columnsMap['employment.person.name'].maxWidth = 240
    columnsMap['employment.person.name'].cellClick = handleSelectedEmployee
    columnsMap['employment.person.name'].format = renderNameWithMessage
    columnsMap['employment.person.name'].headerFilter = true
	
    columnsMap['employmentPpStatusDesc'].minWidth = 150
    columnsMap['employmentPpStatusDesc'].cellClick = handleSelectedEmployee
    columnsMap['employmentPpStatusDesc'].headerFilter = true
    columnsMap['employmentPpStatusDesc'].title = "Status"
    columnsMap['employmentStatusEffDate'].minWidth = 120
    columnsMap['employmentStatusEffDate'].title = "Effective Date"
    columnsMap['employmentStatusEffDate'].format = renderEffectiveDate
    return columnsMap
}

function renderEffectiveDate(value, instance) {
    return moment(value).format(DATE_FORMAT)
} 

function getMessageCommentColumns(editable, handleSelectedMessage, handleTasklist) {
    const columnsMap = new Table.Headers(RemittanceDetail, 'displayedMessages, outstandingTasksCount, employerCmt, reviewedCmt, financialCmt, reasonDaysDiff, confStatus, finalPayOut, personalDataERInitialsCmt, statusConfirmationCmt, statusERInitialsCmt, memtdConfirmationCmt, memtdERInitialsCmt, maritalStatusERInitialsCmt, peERInitialsCmt, contERInitialsCmt, phERInitialsCmt, oneTimeConfirmationCmt, variationERInitialsCmt')
    columnsMap['employerCmt'].editor = editable ? 'input' : null
    columnsMap['employerCmt'].width = 400
    columnsMap['reviewedCmt'].editor = editable ? 'input' : null
    columnsMap['reviewedCmt'].width = 400
    columnsMap['financialCmt'].editor = editable ? 'input' : null
    columnsMap['financialCmt'].width = 400

    const commentsName = ['reasonDaysDiff', 'confStatus', 'finalPayOut',
        'personalDataERInitialsCmt', 'statusConfirmationCmt', 'statusERInitialsCmt', 'memtdConfirmationCmt', 'memtdERInitialsCmt', 'maritalStatusERInitialsCmt', 'peERInitialsCmt', 'contERInitialsCmt', 'phERInitialsCmt', 'oneTimeConfirmationCmt', 'variationERInitialsCmt'
    ];
    for (const commentName of commentsName){
        if(columnsMap[commentName]) columnsMap[commentName].editor = editable ? 'input' : null
        if(columnsMap[commentName]) columnsMap[commentName].width = 400
        // eslint-disable-next-line no-loop-func
        if(columnsMap[commentName]) columnsMap[commentName].format = (value, instance) => {
            // Needed because all comments are defined as STRING in RemittanceDetail.definitions
            // but some of them might actually be dates, so we try to format them as date if possible
            // if their definition is changed to DATE, this code can be removed
            // See src/entities/pension/RemittanceDetail.js
            return (value && moment.utc(value).isValid()) ? moment.utc(value).format(DATE_FORMAT) : value;
        };
    }

    columnsMap['displayedMessages'].format = renderMultipleWarnings
    columnsMap['displayedMessages'].sorter = (a, b) => RefMessage.sort(a.first, b.first)
    columnsMap['displayedMessages'].cellClick = handleSelectedMessage
    columnsMap['displayedMessages'].topCalc = (values, data, calcParams) => ({
            errors: data.reduce((errorCount, detail) => {
                const detailErrors = detail.displayedMessages.filter(message => message.isError()).length;
                return errorCount + detailErrors;
            }, 0), 
            warns: data.reduce((errorCount, detail) => {
                const detailErrors = detail.displayedMessages.filter(message => message.isWarning()).length;
                return errorCount + detailErrors;
            }, 0),
        });
    columnsMap['displayedMessages'].topCalcFormatter = (value, instance) => value.errors + " Errors    -    " + value.warns + " Warnings"
    columnsMap['displayedMessages'].width = 400

    columnsMap['outstandingTasksCount'].cellClick = handleTasklist
    columnsMap['outstandingTasksCount'].topCalc = (values, data, calcParams) => (data.reduce( (tasks, detail) => { return tasks + detail.outstandingTasksCount }, 0))
    columnsMap['outstandingTasksCount'].topCalcFormatter = (value, instance) => value + " Task(s)";
    columnsMap['outstandingTasksCount'].format = (value) => renderNumBadge(value, 'Tasks');
    columnsMap['outstandingTasksCount'].width = 100

    return columnsMap
}

function getEarningColumns (earningTypes, editable, onChange) {
    const categories = EarningType.splitEarningsTypesByCategory(earningTypes)
    var columnsMap = {
        regular: { title: categories.REG.text, name:'regular', columns:[] },
        overtime: { title: categories.OVR.text, name:'overtime', columns:[] },
        oneTime: { title: categories.OTE.text, name:'oneTime', columns:[] },
        nonPensionable: { title: categories.NPE.text, name:'nonPensionable', columns:[] }
    }
    earningTypes.forEach(type => {
        const col = {
            title: type.label, 
            columns: [createEarningsColumn(type)]
        }
        if (type.category.isRegular()) {
            col.columns.push(createHourRateColumn(type))
            columnsMap.regular.columns.push(col)
        } else if (type.category.isOvertime()) {
            col.columns.push(createHourRateColumn(type))
            columnsMap.overtime.columns.push(col)
        } else if (type.category.isPensionable()) {
            columnsMap.oneTime.columns.push(col)
        } else {
            columnsMap.nonPensionable.columns.push(col)
        }
        //HACK fix column offset
        if (col.columns.length === 1) col.columns[0].minWidth=210

    })
    return columnsMap
    
    function createHourRateColumn(type) {
        return { 
            name: 'earnings.' + type.code + '.' + type.hoursOrRate, 
            title: EarningType.getOptionLabel('hoursOrRate', type.hoursOrRate), 
            editor: editable ? 'number' : null, 
            format: type.hoursOrRate === 'hours' ? renderNumberEmptyFor0 : renderAmountEmptyFor0, 
            cellEdited: onChange,
            minWidth: 80, 
            align: 'right', 
            headerSort:false
        }
    }
    function createEarningsColumn(type) {
        return { name:  'earnings.' + type.code + '.amount', 
            title: 'Earnings', 
            format: renderAmountEmptyFor0,
            cellEdited: onChange,
            editor: editable ? 'number' : null, 
            minWidth: 140,
            align: 'right', 
            headerSort: false,
            topCalc: 'sum',
            topCalcFormatter: renderAmountEmptyFor0
        }
    }
}

function renderNameWithMessage(value, instance)  {
	const sev = instance.message && instance.message.severity
    if(!instance.message) return instance.employment.person.name;

	var icon = sev === 'i' ? 'info-circle' :'exclamation-' + (sev === 'e' ? 'triangle' : 'circle')
	var className = 's-';
    if (sev === 'e') className += 'error'
    else if (sev === 'w') className += 'warning'
    else if (sev === 'i') className += 'info'
	return renderToString( <div>
		{instance.employment.person.name}
		<Icon tooltip={instance.message.desc} icon={icon} className={className}/>
  	</div>)
}

function renderContributionWithWarning(value, instance)  {
    const sev = instance.message && instance.message.severity;
    const displayValue = instance.contributions.total !== 0 ? formatAmount(instance.contributions.total) : '';
    if (!['e'].includes(sev)) return displayValue

    return renderToString( <div>
        {displayValue}
        <Icon tooltip='Contributions may be incorrect if error message is not resolved' icon='exclamation-triangle' className='s-error'/>
      </div>)
}

function formatRows(row) {
    if (row.getData().messages?.first?.severity === 'e') row.getElement().className += ' light-error';
}

export { getEarningColumns,}
