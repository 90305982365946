import React, { useState } from 'react'
import { Ref, Definition, RefEvent, RefTask, RefMessage, Auth } from '../../framework/infra'
import { Table, Icon, Button } from '../../framework/controls'
import { Component } from '../../framework/components'
import { Modal, Row, Card } from '../../framework/containers'
import { getSafe, isValueUndefined, moment } from '../../framework/utils/helper'
import { Participation, Employment, Membership } from '../../entities'
import { MembershipService, EmploymentService, ParticipationService } from '../../services'
import { ParticipationEvent } from '../../entities/membership/participation'
import { EmploymentBusiness, MembershipBusiness, ParticipationBusiness } from '../../business'
import useUser from "../../hooks/useUser";

import config from '../../utils/config'
import EventPage from '../../framework/components/page/EventPage'
import MemberParticipationsForm from './MemberParticipationsForm'
import { renderComponent } from '../../framework/utils/renders'
import { ParticipationTaskConfig } from '../../entities/membership/participation/ParticipationTaskConfig'
import { useHistory } from 'react-router-dom'
import HistoryActions from '../../entities/membership/participation/HistoryActions/HistoryActions'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { EmploymentEvent } from '../../entities/employment'
import useNotification from '../../hooks/useNotification'
import { get } from 'lodash'

/**
 * Dynamically get the filter options based on the membership
 * @param {Membership} membership 
 * @returns {Array} filters
 */
const getFilterOptions = (membership) =>{
	const filters = [
		{ text: 'Participation Events',key: 'participationEvent' },
	]

	for (let pp of membership.participations.all) {
		for (let emp of pp.employments.all) {
			if (!filters.find(x=>x.key === `employer_${emp.employer.code}`)) {
				filters.push({ text: `${emp.employer.code} Events`, key: `employer_${emp.employer.code}` });
			}
		}
	}

	return filters;
}

/**
 * Fetch the membership's employments, set the employments in each participation of the membership, and load the pointers of the employments and participations
 * @param {Membership} membership 
 */
const loadAndSetParticipations = async (membership) => {
	try {
		const allEmployments = await EmploymentService.getMemberEmployments(membership.keyValue, {refresh: true});
		for (const participation of (membership.participations?.all ?? [])) {
			// load all events pointers of the participation
			await ParticipationService.loadEventsPointers(participation);
			
			const relevantEmployments = allEmployments.filter(x=> x.keysValues.participation === participation.keyValue);
			// load all events pointers of the employments
			for (const employment of relevantEmployments) {
				await EmploymentService.loadEventsPointers(employment);
			}
			// set employments in the participation
			participation.employments.pushList(relevantEmployments);
		}
	} catch(err){
		console.error('MemberParticipationsClass loadAndSetParticipations err', err)
	}
}

class MemberParticipationsClass extends Component {
	async load() {
		const { membership } = this.props

		return Promise.all([
			Auth.getCurrentUser(),
			loadAndSetParticipations(membership),
		]).then(([user]) => {
			return {show: '', user};
		})
	}
	view() {

		const { selected, showParticipation, showTask, showEvent, user, showForm } = this.state;
		const { participation, membership, openEmployment, filterContext, loadingContext } = this.props;

		const participationsData = Report.getData(membership.participations.all, user, filterContext.get, openEmployment);
		const data = participation ? (participationsData.find(d => d.participation.no === participation.no)?._children ?? []) : participationsData;

		const columns = new Table.Headers(Report, ['treeVal', 'effDt', 'employer', 'statusDesc' , 'eventDesc', 'cmt', 'event.rptDt']);
		columns['treeVal'].width = 70;
		columns['treeVal'].visible = !participation;
		columns['effDt'].width = 150;
		columns['effDt'].headerSort = false;
		columns['effDt'].format = this.effectiveDateOrTaskIcon.bind(this);
		columns['effDt'].align = 'center';
		columns['employer'].headerSort = false;
		columns['employer'].width = 90;
		columns['employer'].format = this.formatEmpLink.bind(this);
		columns['employer'].cellClick = this.handleEmpSelect.bind(this);
		columns['event.rptDt'].maxWidth = 150;
		columns['event.rptDt'].title = 'Reported Date';
		columns['statusDesc'].maxWidth = 90;
		columns['statusDesc'].headerSort = false;
		columns['statusDesc'].visible = config.debug;
		columns['eventDesc'].format = this.formatEventDesc.bind(this);
		columns["cmt"].className = "comment-overflow";
		columns['event.rptDt'].format = this.reportedDateAndSourceIcon.bind(this);

		const processUpdateStack = async (response) => {
			loadingContext.set(true);
			try {
				await response.updateStack.commit();
			} catch (e) {
				console.error('Error committing update stack', e)
				this.props.notify(e.message, 'danger')
			}

			this.finalizeSave();
		}

		const actions =  HistoryActions.getActions(participation)
		const handleAction = (action) => {
			action.execute({
				participation: participation, 
				formContainerSetter: (form) => {this.setState({showForm: form })},
				callback: processUpdateStack,
				onError: (e) => { this.props.notify(e.message, 'danger') }
			})
		}

		const handleNewEvent = (event) => {
			this.setState({selected: {event: event, employment: openEmployment, participation: participation}, showEvent: true});
		}

		// either we are viewing in the context of an employment, or we are viewing the participation or maybe all participations via member view
		const viewType = openEmployment ? 'employment' : participation ? 'participation' : 'member';


		const filters = getFilterOptions(membership);

		return  <><Card framed>
				<Row className='justify-content-end g5'>
					{viewType !== 'member' && <DropdownButton
						title={actions.length === 0 ? "No Actions": "Actions"}
						variant="secondary"
						className="no-mar-l no-mar-r"
						{...actions.length === 0 && {disabled: true}}
					>
						{actions.map((action, index) => {
							return <Dropdown.Item key={index} onClick={() => handleAction(action)} >{action.text}</Dropdown.Item>
						})}
					</DropdownButton>}
					{viewType === 'employment' && <Button onClick={() => handleNewEvent(new EmploymentEvent())} className="btn-secondary no-mar-l no-mar-r pad-lr-10">
						Add Employment Event
					</Button>}
					{viewType !== 'member' && <Button onClick={() => handleNewEvent(new ParticipationEvent())} className="btn-secondary no-mar-l no-mar-r pad-lr-10">
						Add Participation Event
					</Button>}
					<DropdownButton
						title={"Filters"}
						variant="secondary"
						className="no-mar-l"
					>
						{filters.map((filter, index) => {
							const isSelected = filter.key === filterContext.get;
							return <Dropdown.Item key={index} onClick={() => filterContext.set(isSelected ? null : filter.key)} >{filter.text} {isSelected && <Icon icon='check' />}
							</Dropdown.Item>
						})}
					</DropdownButton>
				</Row>
			</Card>
			{user && <Table id="participation-list-table" 
				className="mih-400"
				data={data} 
				columns={columns} 
				onSelect={this.handleSelect.bind(this)} 
				dataTree={!participation}
				dataTreeStartExpanded={(row) => !row.getPrevRow()} //We want to open the first tree 
				rowFormatter={this.handleSeverity.bind(this)}
			/>}
			{selected && showParticipation && <Modal className='h-100 w-80 modal-bg-color'>
				<MemberParticipationsForm participation={selected.participation} membership={membership} onCancel={this.handleCancel.bind(this)} onSave={this.handleSaveParticipation.bind(this)} notify={this.props.notify} handleDelete={this.onPPDelete.bind(this)}/>
			</Modal>}
			{selected && showTask && showTask !== '' && <Modal className='h-100 w-70 modal-bg-color'>
				{showTask}
			</Modal>}
			{selected && showEvent && <Modal className='w-60 modal-bg-color' >
				<EventPage event={selected.event} employment={selected.employment} participation={selected.participation} onSave={this.handleSaveEvent.bind(this)} onCancel={this.handleCancel.bind(this)} eventParameters={{openEmployment}}/>
			</Modal>}
			{ showForm && <Modal className='w-40 modal-bg-color' >
				{showForm}
			</Modal>}
		</>
	}

	effectiveDateOrTaskIcon = (value, instance) => {
		let columnValue = renderComponent(<>
			{value} {instance?.event?.guessed ? <Icon 
				icon='question-circle'
				tooltip='This date has been guessed'
				tooltip-right
				className='text-primary'
			/> : <></>}</>)
		
		if (instance.task.code) {
			columnValue = renderComponent(instance.task.hasFlow() ?
					<Icon 
						icon='exclamation-circle'
						large
						tooltip='This task has a resolution flow.'
						tooltip-right
						className='text-primary'
					/>
					: <></>
				)
		} else if (instance.event instanceof ParticipationEvent && instance.event.config.shouldHideDate) {
			columnValue = '';
		}

		return columnValue;
	}

	reportedDateAndSourceIcon = (value, instance) => {
		let source = instance.event.sourceValue;
		let columnValue = renderComponent(<>
			{value}  {source ? <Icon 
				icon={source.icon}
				tooltip={source.text(instance.event)}
				tooltip-left
				className='text-primary'
			/> : <></>}</>)
		
		return columnValue;
	}

	formatEventDesc = (value, instance) => {

		if (instance.task?.config?.isMultipleResolution || instance.event?.subEvent?.length > 0) {
			return renderComponent(
				<><Icon 
					icon='list'
					tooltip='Task with multiple items to resolve'
					tooltip-right
				/>  {value} </> )
		}

		return value;
	}

	
	async handleDeleteEmployment(employment) {
		const { membership } = this.props;
		const targetPP = membership.participations.all.find((pp) => pp.keyValue === employment.participation.keyValue);
		targetPP.employments.pullFilter((emp) => emp.keyValue !== employment.keyValue);
	}

	onPPDelete(participation){
		return this.props.handleDeletePP(participation).then(() => {
			this.setState({showParticipation: false });
		})
	}
	handleSelect(row) { 
		const {user} = this.props;
		if ((row.getData().page === 'task' && row.task.form) || row.event.config.showTask) {
			let task = row.task;
			if (row?.event?.config?.showTask) {
				task = row.event.config.showTask;
			}
			const props = task.form.props;
			var fixer = React.cloneElement(task.form, {
				startDate: props.definedDate ? task.params[props.definedDate].value : undefined,
				instance: props.isEmployment ? row.employment : row.participation,
				onClose: this.handleCancel.bind(this),
				onSave: this.handleTaskCompleted.bind(this),
				task: task
			})
			this.setState({selected: row, showTask: fixer})
		}
		else if (row.getData().page === 'participation') this.setState({selected: row, showParticipation: true})
		else if (row.getData().page === 'event') {
			if(Boolean(row.event?.config?.onlySuperAdminEdit) && !user?.isSuperAdmin) {
				return;
			}
			this.setState({selected: row, showEvent: true});
		}
	}

	handleEmpSelect(e, cell) {
		e.stopPropagation()
		// in the cell data, employer is a string with the employer code (for example "CRCO").
		// All employment events and participation events that have an employer ref have a value for employer.
		// But only the employment events have the full employer object in the employment.
		// That's why we also check the employer in the employment (to check if we make the employer code clickable or read only).
		const report = cell?.getData?.();
		if (report?.employer && report?.employment?.employer?.code) {
			const { history } = this.props;
			history.push(`/employer/${report.employment.employer.code}/employment/${report.employment.keyValue}`);
		}
	}
	formatEmpLink(value, data, cell) {
		// in the cell data, employer is a string with the employer code (for example "CRCO").
		// All employment events and participation events that have an employer ref have a value for employer.
		// But only the employment events have the full employer object in the employment.
		// That's why we also check the employer in the employment (to check if we make the employer code clickable or read only).
		if (cell?.getData?.().employer && cell?.getData?.().employment?.employer?.code) cell.getElement().className += ' tabulator-cell-link'
		return value
	}
	handleCancel() { 
		this.setState({showParticipation: false, showEmployment: false, showTask: '', showEvent: false });
		if(this.props.onCancel) this.props.onCancel();
	}
	async handleTaskCompleted(instance) { 
		const { membership, loadingContext } = this.props;
		if (loadingContext) loadingContext.set(true);
		const isEmployment = instance instanceof Employment;
		if (isEmployment) { 
			EmploymentBusiness.validate(instance);
			await EmploymentService.updateEmployment(instance);
		} else {
			MembershipBusiness.reassignNestedParticipations(membership);
			await MembershipService.update(membership);
		}

		this.setState({ showTask: "" });
		this.finalizeSave();
	}

	async handleSaveEvent(event) {
		const { selected } = this.state;
		var { membership, loadingContext } = this.props;

		if(loadingContext) loadingContext.set(true);

		const ppToFind = membership?.participations?.find(pp => selected?.participation?.no && pp.no === selected.participation.no);
		const empToFind = ppToFind?.employments?.find(emp => selected?.employment?.keyValue && emp.keyValue === selected.employment.keyValue);
		if (empToFind) {
			empToFind.events = selected.employment.events;
		}
		if (ppToFind) {
			ppToFind.events = selected.participation.events;
		}
		const isPPEvent = event instanceof ParticipationEvent;
		if(!isPPEvent) {
			EmploymentBusiness.validate(selected.employment);
			await MembershipService.update(selected.employment.participation.membership, ppToFind?.no);
			await EmploymentService.updateEmployment(selected.employment);
		} else {
			await MembershipService.update(membership, ppToFind?.no);
		}

		this.setState({ showEvent: false}) 
		this.finalizeSave();
	}

	finalizeSave() {
		const { selected } = this.state;
		var { openEmployment, loadingContext, membership } = this.props;

		if (openEmployment && selected?.employment?.keyValue === openEmployment.keyValue) openEmployment.events = selected.employment.events
		if (this.props.notify) {this.props.notify('Information was successfully saved', 'success')}
		if (this.props.onSave) this.props.onSave()
		if (loadingContext) loadingContext.set(false);
	}

	handleSeverity(row) { 
		const { user } = this.state;
		const isHistHidden = row._row.data?.event?.config?.histHidden;

		if (row.getData().severity === 'w') row.getElement().className += ' warning' 
		else if (row.getData().severity === 't') row.getElement().className += ' todo'
		else if (row.getData().severity === 'e') row.getElement().className += ' light-error'
		else if (isHistHidden && user.isSuperAdmin) row.getElement().className += ' greyed-out'
	}
	handleSaveParticipation() {
		this.setState({showParticipation: false})
		if (this.props.onSave) this.props.onSave()
	}
}

export default function MemberParticipations(props) {

	const user = useUser();
	const history = useHistory();
	const [filter, setFilter] = useState();
	const { addMessage } = useNotification();
	
	return <MemberParticipationsClass {...props} 
		user={user} 
		history={history} 
		filterContext={{get: filter, set: setFilter}} 
		notify={addMessage} />;
}

class Report extends Ref { //ReportParticipations
	/**
	 * 
	 * @param {*} participations 
	 * @param {*} user 
	 * @param {*} filterKey 
	 * @param {Employment | undefined} selectedEmployment 
	 * @returns 
	 */
    static getData(participations, user, filterKey, selectedEmployment) {
        return participations.reduce((rows, pp) => {
			var participation = ParticipationBusiness.validate(pp, selectedEmployment ? pp.isLastEmployment(selectedEmployment) : undefined);

			// Employment with last status change
			const lastEmployment = [...participation.employments.all].sort((a, b) => 
				a.events?.statusEvents?.latest?.effDt && b.events?.statusEvents?.latest?.effDt
				? moment(b.events.statusEvents?.latest?.effDt).valueOf() - moment(a.events.statusEvents?.latest?.effDt).valueOf()
				: 0
			)[0];
			const row = new Report({ treeVal: participation.ppNo, effDt: participation.lastStatusEvent.effDt, eventDesc: participation.statusDesc, employer: lastEmployment?.employer?.code, employment: lastEmployment, event: participation.lastStatusEvent, participation: participation, page: 'participation' })
			rows.push(row)
			row._children = []
			//ReportParticipation
			participation.events.filter(event => (user.isSuperAdmin || (event.config && !event.config.histHidden)) && (!event.config.canShow || event.config.canShow({instance: participation, event: event}))).forEach(ev => {
				if (!isValueUndefined(filterKey) && filterKey !== 'participationEvent') return;
				row._children.push(new Report({ effDt: ev.effDt, eventDesc: ev.eventDesc, statusDesc: ev.stsCode, cmt: ev.cmt, severity: ev.severity, event: ev, participation: participation, page: 'event', employer: ev.pointers?.all?.find(x => x.name ==='employer')?.instance?.shortDesc }))
			})

			participation.employments.forEach((ee, i)  => {
				var employment = EmploymentBusiness.validate(ee, i === (participation.employments.length - 1));
				if (!isValueUndefined(filterKey) && filterKey !== `employer_${ee.employer.code}`) return;
				employment.events.filter(event => user.isSuperAdmin || (event.config && !event.config.histHidden)).forEach(event => row._children.push(new Report({ effDt: event.effDt, statusDesc: event.stsCode,  eventDesc: event.eventDesc, cmt: event.cmt, severity: event.severity, employer: employment.employer.shortDesc, employment: employment, event: event, participation: participation, page: 'event' })))
				employment.tasks.forEach(task => row._children.push(new Report({effDt: task.effDt, eventDesc: task.desc, cmt: task.cmt, severity: task.severity, employer: employment.employer.shortDesc, task: task, employment: employment, participation: participation, page: 'task'})))
				if (participation.lastStatusEvent.status.shouldShowTask()) {
					employment.messages.forEach(message => row._children.push(new Report({effDt: message.effDt, eventDesc: message.desc, cmt: message.cmt, severity: message.severity, employer: employment.employer.shortDesc, message: message, employment: employment, participation: participation, page: 'message'})))
				}
			})
			row._children = row._children.sort((a, b) => {
				let result = 0;
				const effectiveDateA = moment(a.effDt);
				const effectiveDateB = moment(b.effDt);

				if (effectiveDateA.isSame(effectiveDateB)) {
					result = RefEvent.compareEvents(a.event, b.event);
				} else if (effectiveDateA.isBefore(effectiveDateB)) {
					result = -1;
				} else {
					result = 1;
				}
				return result;
			})
			rows.sort((a,b) => a.participation.no > b.participation.no ? -1 : 1)
			if (participation.lastStatusEvent.status.shouldShowTask()) {
				participation.messages.forEach(message => {
					row._children.push(new Report({ effDt: message.effDt, eventDesc: message.desc, cmt: message.cmt, severity: message.severity, message: message,participation: participation, page: 'message' }))
				})
			}
			
			participation.tasks.forEach(task => {
				let taskDesc = task.desc;
				if (ParticipationTaskConfig[task.code].isMultipleResolution) {
					taskDesc = participation.getFlowStatusDesc(participation.events.findOrCreate('code', { code: task.code }));
				}
				row._children.push(new Report({ effDt: task.effDt, eventDesc: taskDesc, cmt: task.cmt, severity: task.severity, task: task, participation: participation, page: 'task' }));
			})

			row._children.sort((a,b) => {
				if (a.page === 'task') return 1;
				else if (b.page === 'task') return -1;
				else return 0;
			})

            return rows
        }, [])
    }
    
    static definitions = {
        treeVal: { type: Definition.types.STRING, text: 'No' },
        participation: { ref: Participation, text: 'Participation' },
		employment: {ref: Employment, text: 'Employment'},
		eventDesc: { type: Definition.types.STRING, text: 'Description'},
		statusDesc: { type: Definition.types.STRING, text: 'Status'},
		event: { ref: RefEvent },
		task: { ref: RefTask},
		message: { ref: RefMessage },
		effDt: { type: Definition.types.DATE, text: 'Date'},
		cmt: { type: Definition.types.STRING, text: 'Comment'},
		employer: {type: Definition.types.STRING, text: 'Entity'},
		page: { type: Definition.types.STRING },
		severity: { type: Definition.types.STRING },
    }
}